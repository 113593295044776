import { useEffect } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Notifications() {

    useEffect(() => {
        
    }, []);

    

    return (
        <div>
            <p>Notifications</p>
        </div>
    );
}
